import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;
const AdminToken = process.env.REACT_APP_ADMIN_TOKEN_KEY;

export const getAllLocationByDateApi = async (date) => {
  try {
    // ส่งคำขอ POST พร้อมกับ token ใน headers
    const response = await axios.post(
      `${URL}/api/activity/getAllLocationByDate`,
      { date },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Admin': AdminToken
        }
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
      console.error('Error response:', error.response);
    } else if (error.request) {
      // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
      console.error('Error request:', error.request);
    } else {
      // เกิดข้อผิดพลาดในขณะตั้งคำขอ
      console.error('Error message:', error.message);
    }
  }
};

export const getAllLocationApi = async () => {
  try {
    // ส่งคำขอ POST พร้อมกับ token ใน headers
    const response = await axios.get(
      `${URL}/api/activity/getAllLocation`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Admin': AdminToken
        }
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
      console.error('Error response:', error.response);
    } else if (error.request) {
      // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
      console.error('Error request:', error.request);
    } else {
      // เกิดข้อผิดพลาดในขณะตั้งคำขอ
      console.error('Error message:', error.message);
    }
  }
};

export const fetchActivitiesByDate = async (date) => {
  try {
    // ส่งคำขอ POST พร้อมกับ token ใน headers
    const response = await axios.post(
      `${URL}/api/activity/getAllActivityByDate`,
      { date },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Admin': AdminToken
        }
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
      console.error('Error response:', error.response);
    } else if (error.request) {
      // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
      console.error('Error request:', error.request);
    } else {
      // เกิดข้อผิดพลาดในขณะตั้งคำขอ
      console.error('Error message:', error.message);
    }
  }
};

export const submitQuestionnaireData = async (email, answers, suggestion) => {
    try {
      const requestBody = {
        email: email, // ส่งอีเมลของผู้ใช้
        questionnaire: {
          answers: answers, // คำตอบจากผู้ใช้
          suggestion: suggestion, // ข้อเสนอแนะ (ถ้ามี)
        },
      };
  
      const response = await axios.post(
        `${URL}/api/questionnaire/takeQuestionnaire`, // ใช้ URL ของ API ที่ต้องการ
        requestBody, // ส่งข้อมูล request body
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Admin': AdminToken
          },
        }
      );
      return response.data; // ส่งข้อมูลที่ได้รับจาก API
    } catch (error) {
      if (error.response) {
        // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
        console.error('Error response:', error.response);
      } else if (error.request) {
        // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
        console.error('Error request:', error.request);
      } else {
        // เกิดข้อผิดพลาดในขณะตั้งคำขอ
        console.error('Error message:', error.message);
      }
      return null; // หากเกิดข้อผิดพลาด ส่งค่า null กลับ
    }
  };

  export const logGPS = async (latitude,longitude) => {
    try {
      const requestBody = {
        latitude: latitude, // ส่งอีเมลของผู้ใช้
        longitude:longitude
      };
  
      const response = await axios.post(
        `${URL}/api/log/logGps`, // ใช้ URL ของ API ที่ต้องการ
        requestBody, // ส่งข้อมูล request body
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Admin': AdminToken
          },
        }
      );
      return response.data; // ส่งข้อมูลที่ได้รับจาก API
    } catch (error) {
      if (error.response) {
        // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
        console.error('Error response:', error.response);
      } else if (error.request) {
        // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
        console.error('Error request:', error.request);
      } else {
        // เกิดข้อผิดพลาดในขณะตั้งคำขอ
        console.error('Error message:', error.message);
      }
      return null; // หากเกิดข้อผิดพลาด ส่งค่า null กลับ
    }
  };

  export const logNotification = async (latitude,longitude,location) => {
    try {
      const requestBody = {
        latitude: latitude, // ส่งอีเมลของผู้ใช้
        longitude:longitude,
        location:location
      };
  
      const response = await axios.post(
        `${URL}/api/log/logNotification`, // ใช้ URL ของ API ที่ต้องการ
        requestBody, // ส่งข้อมูล request body
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Admin': AdminToken
          },
        }
      );
      return response.data; // ส่งข้อมูลที่ได้รับจาก API
    } catch (error) {
      if (error.response) {
        // เซิร์ฟเวอร์ตอบกลับด้วยรหัสสถานะที่ไม่ใช่ 2xx
        console.error('Error response:', error.response);
      } else if (error.request) {
        // ไม่มีการตอบกลับจากเซิร์ฟเวอร์
        console.error('Error request:', error.request);
      } else {
        // เกิดข้อผิดพลาดในขณะตั้งคำขอ
        console.error('Error message:', error.message);
      }
      return null; // หากเกิดข้อผิดพลาด ส่งค่า null กลับ
    }
  };