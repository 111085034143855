import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import MapGPS from "../components/Map.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import {
  logNotification,
  getAllLocationByDateApi,
} from "../Service/userApi.jsx";

//Img
import Banner from "../Assets/Survey_Banner.png";
import schedule from "../Assets/schedule.png";
import btnTH from "../Assets/th_survey.png";
import btnEN from "../Assets/en_survey.png";

const LandingPage = () => {
  const navigate = useNavigate();

  const [locationData, setLocationData] = useState([]); // สเตทเก็บข้อมูลหมุด
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // ใช้เพื่อเปิด/ปิด modal
  const [location, setLocation] = useState({
    name: "",
    url: "",
    activities: [],
    isActive: false,
  }); // สถานที่ที่จะนำไปแสดงใน modal
  const [isLoad, setIsLoad] = useState(true);

  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  let locationId = params.get("locationid");
  const name = params.get("activity");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  //Modal Map
  const handleLocationClick = (e, name, url, activities, isActive) => {
    e.preventDefault(); // ป้องกันการกระโดดไปลิงก์โดยตรง
    setLocation({ name, url, activities, isActive });
    setIsModalOpen(true);
  };

  // ฟังก์ชันปิด modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMapClick = (e, name, url, activities, isActive) => {
    e.preventDefault();
    setLocation({ name, url, activities, isActive });
    setIsModalOpen(true);
  };

  const getCurrentEvents = (events) => {
    // Get current time in Bangkok timezone
    const now = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Bangkok",
    });
    const nowBangkok = new Date(now);
    const currentEvents = events.filter((event) => {
      const startTime = new Date(
        new Date(event.start_time).toLocaleString("en-US", {
          timeZone: "Asia/Bangkok",
        })
      );
      const endTime = new Date(
        new Date(event.end_time).toLocaleString("en-US", {
          timeZone: "Asia/Bangkok",
        })
      );

      // Adjust startTime to 15 minutes earlier
      startTime.setMinutes(startTime.getMinutes() - 15);

      return nowBangkok >= startTime && nowBangkok <= endTime;
    });
    return currentEvents;
  };

  const filterNextActivities = (activitiesData) => {
    // เวลาปัจจุบันในกรุงเทพ (UTC+7)
    const now = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Bangkok",
    });
    const nowBangkok = new Date(now);

    return activitiesData
      .map((location) => {
        // กรองกิจกรรมที่ยังไม่เริ่มต้นเท่านั้น (start_time > now)
        const upcomingActivities = location.activities.filter((activity) => {
          const startTime = new Date(activity.start_time).toLocaleString(
            "en-US",
            { timeZone: "Asia/Bangkok" }
          );
          return new Date(startTime) > nowBangkok;
        });

        // หา next event ที่จะเกิดขึ้น (start_time ที่ใกล้ที่สุด)
        const nextEvent = upcomingActivities.reduce((closest, activity) => {
          const startTime = new Date(activity.start_time).toLocaleString(
            "en-US",
            { timeZone: "Asia/Bangkok" }
          );
          const startTimeDate = new Date(startTime);
          return !closest || startTimeDate < new Date(closest.start_time)
            ? activity
            : closest;
        }, null);

        // ถ้าไม่มีกิจกรรมที่จะเกิดขึ้นเลย ให้คืนค่า null
        return nextEvent ? { ...location, activities: [nextEvent] } : null;
      })
      .filter((location) => location !== null); // ลบข้อมูลที่เป็น null ออก
  };

  // const filterActivities = (activitiesData) => {
  //   // กำหนดเวลาปัจจุบันในโซนเวลาของกรุงเทพ (GMT+7)
  //   const now = new Date();
  //   const bangkokOffset = 7 * 60; // Bangkok is UTC+7
  //   const currentBangkokTime = new Date(
  //     now.getTime() + bangkokOffset * 60 * 1000
  //   );

  //   return activitiesData
  //     .map((location) => {
  //       const filteredActivities = location.activities.filter((activity) => {
  //         const startTime = new Date(activity.start_time);
  //         const endTime = new Date(activity.end_time);

  //         // แปลง start_time และ end_time ไปเป็นเวลาในกรุงเทพ (UTC+7)
  //         startTime.setMinutes(startTime.getMinutes() + bangkokOffset);
  //         endTime.setMinutes(endTime.getMinutes() + bangkokOffset);

  //         // ตรวจสอบว่าเวลาปัจจุบันอยู่ระหว่าง start_time และ end_time หรือไม่
  //         return (
  //           currentBangkokTime >= startTime && currentBangkokTime <= endTime
  //         );
  //       });

  //       return {
  //         ...location,
  //         activities: filteredActivities,
  //       };
  //     })
  //     .filter((location) => location.activities.length > 0); // กรองออกถ้าไม่มีกิจกรรมที่ตรง
  // };

  useEffect(() => {
    getAllLocationByDateApi()
      .then((data) => {
        console.log("Data from API:", data);
        if (
          data &&
          data.data &&
          data.data.locations &&
          data.data.locations.length > 0
        ) {
          setLocationData(data.data.locations);

          if (locationId > 0) {
            if (data.data?.locations?.length > 0) {
              const found = data.data.locations.find(
                (item) => item.id === parseInt(locationId)
              );
              if (found) {
                const url = found.map_url;
                const activities = found.activities;
                const isActive = found.isActive;
                setLocation({ name, url, activities, isActive });
                setIsModalOpen(true);

                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(
                    (position) => {
                      const latitude = position.coords.latitude;
                      const longitude = position.coords.longitude;
                      logNotification(latitude, longitude, found.name);
                    },
                    (error) => {
                      console.error("Error getting GPS position:", error);
                    }
                  );
                } else {
                  console.log("Geolocation is not supported by this browser.");
                }
              }
            }

            // ล้าง query parameters ออกจาก URL
            if (loc.search) {
              navigate(loc.pathname, { replace: true });
            }
          }

          setIsLoad(false);
        } else {
          console.log("ไม่พบข้อมูลหมุด");
        }
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูลจาก API:", error);
      });

    // ปิดการเลื่อนของ body เมื่อ sidebar เปิดอยู่
    document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";
    return () => {
      // คืนค่าให้ body สามารถเลื่อนได้เมื่อ component ถูก unmount
      document.body.style.overflow = "auto";
    };
  }, [locationId, isSidebarOpen]);

  useEffect(() => {
    if (locationData.length > 0) {
      const data = filterNextActivities(locationData);
      if (data.length > 0) {
        const nextEvent = data[0].activities[0];
        const startTime = new Date(nextEvent.start_time).toLocaleString(
          "en-US",
          { timeZone: "Asia/Bangkok" }
        );
        const startTimeBangkok = new Date(startTime);
        const now = new Date().toLocaleString("en-US", {
          timeZone: "Asia/Bangkok",
        });
        const nowBangkok = new Date(now);
        // const timeUntilNextEvent = startTimeBangkok - nowBangkok;
        // Calculate time until next event starts, subtracting 15 minutes (900000 ms)
        const timeUntilNextEvent = startTimeBangkok - nowBangkok;

        if (timeUntilNextEvent > 0) {
          const timerId = setTimeout(() => {
            setLocation({
              name: data[0].name,
              url: data[0].url,
              activities: data[0].activities,
              isActive: true,
            });
            setIsModalOpen(true);
          }, timeUntilNextEvent);

          // Clean up the timeout if the component unmounts or dependencies change
          return () => clearTimeout(timerId);
        }
      }
    }
  }, [locationData]);

  return (
    <>
      <div
        style={{
          //position: 'fixed',
          top: 0,
          width: "100%",
          background: "white",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <header>
          <nav
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              background: "white",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              overflow: "hidden",
              whiteSpace: "nowrap",
              padding: "0 20px",
            }}
          >
            <div
              onClick={toggleSidebar}
              style={{
                cursor: "pointer",
                color: "#B57947",
                fontSize: "33px",
              }}
            >
              <FontAwesomeIcon icon={isSidebarOpen ? faCircleXmark : faBars} />
            </div>
            <div
              style={{
                textAlign: "right",
                color: "#B57947",
                fontSize: "20px",
              }}
            >
              <p>
                <strong>
                  ประเพณีลอยกระทงเผาเทียนเล่นไฟ <br /> จังหวัดสุโขทัย ประจำปี
                  2567
                </strong>
              </p>
            </div>
          </nav>
          {isSidebarOpen && (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000000BF", // สีพื้นหลังโปร่งใส
                  zIndex: 998,
                }}
                onClick={toggleSidebar}
              ></div>{" "}
              {/* Overlay ที่ปิด Sidebar เมื่อคลิก */}
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "80%",
                  background: "white",
                  boxShadow: "2px 0 5px rgba(0,0,0,0.5)",
                  zIndex: 999,
                  padding: "110px 10px 10px 10px",
                  overflowY: "scroll",
                  boxSizing: "border-box",
                }}
              >
                <button style={styles.btnLocation}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ fontSize: "24px", textAlign: "left" }}
                  />
                  <p
                    style={{
                      fontSize: "17px",
                      flexGrow: 1,
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    สถานที่จัดกิจกรรม
                  </p>
                </button>
                <div
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    wordWrap: "break-word",
                    boxSizing: "border-box",
                    padding: "2px",
                    whiteSpace: "normal",
                  }}
                >
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ประตูงาน",
                          "https://www.google.com/maps?q=17.018566,99.706907"
                        )
                      }
                      style={styles.textmap}
                    >
                      1. ประตูงาน (Main Fesival Gate)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตลาดโบราณเมืองศรีสัชนาลัย",
                          "https://www.google.com/maps?q=17.0186,99.706615"
                        )
                      }
                      style={styles.textmap}
                    >
                      2.ตลาดโบราณเมืองศรีสัชนาลัย (Sri Satchanalai Ancient
                      Market)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ลานโอทอป (OTOP market)",
                          "https://www.google.com/maps?q=17.018623,99.706395"
                        )
                      }
                      style={styles.textmap}
                    >
                      3.ลานโอทอป (OTOP market)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตรอกช่างทอง ชุมชนผ้าทอสุโขทัย",
                          "https://www.google.com/maps?q=17.01882,99.704988"
                        )
                      }
                      style={styles.textmap}
                    >
                      4.ตรอกช่างทอง ชุมชนผ้าทอสุโขทัย (Goldsmith Alley,
                      Sukhothai Hand-WovenTextile Community)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ลานกิจกรรมหน้าวัดชนะสงคราม",
                          "https://www.google.com/maps?q=17.018715,99.704859"
                        )
                      }
                      style={styles.textmap}
                    >
                      5.ลานกิจกรรมหน้าวัดชนะสงคราม (Creative Space : Wat
                      Chanasongkram)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "มหกรรมสินค้าสหกรณ์ ตลาดหอการค้า",
                          "https://www.google.com/maps?q=17.018715,99.704859"
                        )
                      }
                      style={styles.textmap}
                    >
                      6.มหกรรมสินค้าสหกรณ์ ตลาดหอการค้า
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ทางเข้า light and sound show",
                          "https://www.google.com/maps?q=17.01828,99.704349"
                        )
                      }
                      style={styles.textmap}
                    >
                      7.ทางเข้า light and sound show
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "วัดมหาธาตุ LIGHT and SOUND SHOW Wat Mahathat",
                          "https://www.google.com/maps?q=17.018218,99.702766"
                        )
                      }
                      style={styles.textmap}
                    >
                      8.วัดมหาธาตุ LIGHT and SOUND SHOW Wat Mahathat
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "พระบรมราชานุสาวรีย์พ่อขุนรามคำแหงมหาราช",
                          "https://www.google.com/maps?q=17.018661,99.704841"
                        )
                      }
                      style={styles.textmap}
                    >
                      9.พระบรมราชานุสาวรีย์พ่อขุนรามคำแหงมหาราช (King
                      Ramkhamhaeng the Great Monument)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "วัดชนะสงคราม",
                          "https://www.google.com/maps?q=17.01882,99.704988"
                        )
                      }
                      style={styles.textmap}
                    >
                      10.วัดชนะสงคราม (Wat Chanasongkram)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ศาลหลักเมือง",
                          "https://www.google.com/maps?q=17.018098,99.703953"
                        )
                      }
                      style={styles.textmap}
                    >
                      11.ศาลหลักเมือง (City Pillar Shrine)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตลาดบ้านบ้าน โขทัย",
                          "https://www.google.com/maps?q=17.017913,99.702772"
                        )
                      }
                      style={styles.textmap}
                    >
                      12.ตลาดบ้านบ้าน โขทัย (Ban Ban Sukhothai Green Market)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ลานวัฒนธรรม",
                          "https://www.google.com/maps?q=17.018095,99.702362"
                        )
                      }
                      style={styles.textmap}
                    >
                      13.ลานวัฒนธรรม (Cultural Plaza)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ลานกิจกรรมและการท่องเที่ยวโดยชุมชน",
                          "https://www.google.com/maps?q=17.018267,99.702432"
                        )
                      }
                      style={styles.textmap}
                    >
                      14.ลานกิจกรรมและการท่องเที่ยวโดยชุมชน (Creative Space and
                      CBT)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "จุดประกวด กระทงใหญ่",
                          "https://www.google.com/maps?q=17.018367,99.702444"
                        )
                      }
                      style={styles.textmap}
                    >
                      15.จุดประกวด กระทงใหญ่ (Grand Krathong Contest Venue)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตระพังตระกวน จุดลอยกระทง ชมพลุตะไลไฟพะเนียง",
                          "https://www.google.com/maps?q=17.018733,99.702474"
                        )
                      }
                      style={styles.textmap}
                    >
                      16.ตระพังตระกวน จุดลอยกระทง ชมพลุตะไลไฟพะเนียง (Traphang
                      Trakuan: Loy Krathong Spot, Fireworks, and Sky Lanterns
                      Viewing Point)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "กระทงพระราชทาน",
                          "https://www.google.com/maps?q=17.020062,99.701149"
                        )
                      }
                      style={styles.textmap}
                    >
                      17.กระทงพระราชทาน (Royal Krathong)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตลาดโบราณรอบรั้วเมืองพระร่วง",
                          "https://www.google.com/maps?q=17.018449,99.701966"
                        )
                      }
                      style={styles.textmap}
                    >
                      18.ตลาดโบราณรอบรั้วเมืองพระร่วง (Praroung Ancient Market)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตลาดแลกเบี้ย",
                          "https://www.google.com/maps?q=17.017878,99.701486"
                        )
                      }
                      style={styles.textmap}
                    >
                      19.ตลาดแลกเบี้ย
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "สระยายเพิ้ง",
                          "https://www.google.com/maps?q=17.018068,99.700774"
                        )
                      }
                      style={styles.textmap}
                    >
                      20.สระยายเพิ้ง (Yai Perng Pond)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "แพกระทงใหญ่ ตระพังตาล",
                          "https://maps.google.com/maps?q=17.018001,99.700709"
                        )
                      }
                      style={styles.textmap}
                    >
                      21.แพกระทงใหญ่ ตระพังตาล (GRAND KRATHONG Tra Pang Tarn)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ตระพังตาล (ตรงข้ามวัดสระศรี)",
                          "https://www.google.com/maps?q=17.018192,99.70073"
                        )
                      }
                      style={styles.textmap}
                    >
                      22.ตระพังตาล (ตรงข้ามวัดสระศรี) (Tra Pangtarn - Wat
                      Srasri)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "วัดสระศรี",
                          "https://www.google.com/maps?q=17.018672,99.70128"
                        )
                      }
                      style={styles.textmap}
                    >
                      23.วัดสระศรี (Wat Srasri)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "ท่าน้ำลอยประทีปพระราชทาน",
                          "https://www.google.com/maps?q=17.018871,99.701479"
                        )
                      }
                      style={styles.textmap}
                    >
                      24.ท่าน้ำลอยประทีปพระราชทาน (Royal Lantern Floating Pier)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "โรงละครกลางแจ้ง",
                          "https://www.google.com/maps?q=17.020062,99.701149"
                        )
                      }
                      style={styles.textmap}
                    >
                      25.โรงละครกลางแจ้ง (Open-Air Theater)
                    </a>
                  </p>
                  <p>
                    <a
                      href="#"
                      onClick={(e) =>
                        handleLocationClick(
                          e,
                          "กองอำนวยการ",
                          "https://www.google.com/maps?q=17.018821,99.704403"
                        )
                      }
                      style={styles.textmap}
                    >
                      26.กองอำนวยการ (Tourist Service and Information Center)
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
        </header>
        <main>
          <div style={styles.container}>
            <div>
              <h1
                style={{
                  color: "#B57947",
                  fontSize: "5svw",
                  fontWeight: "bold",
                }}
              >
                พื้นที่กิจกรรมภายในงาน
              </h1>
              <div style={{ position: "relative" }}>
                {!isLoad && (
                  <MapGPS
                    style={styles.imgPlan}
                    locations={locationData}
                    onMapClick={handleMapClick}
                  />
                )}
              </div>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <img
                src={Banner}
                alt="Banner"
                style={{ width: "100%", cursor: "pointer" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "60%",
                  right: "10%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <button
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLScdQhpuemi17hnWdGKxz8nHWWIjoIOvz-XbSFzBBhn71UFAxA/viewform?usp=sf_link",
                      "_blank"
                    )
                  }
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "40vw", // ปรับขนาดตาม viewport width
                  }}
                >
                  <img src={btnTH} alt="btnTH" style={{ width: "100%" }} />
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSes56iOc3vCGXpmhIfqOOjyt1JJrsR7sJxEwFcK4lLNlhBrRg/viewform?usp=sf_link",
                      "_blank"
                    )
                  }
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    width: "40vw", // ปรับขนาดตาม viewport width
                  }}
                >
                  <img src={btnEN} alt="btnEN" style={{ width: "100%" }} />
                </button>
              </div>
            </div>
            <div style={{ padding: 0 }}>
              <button
                onClick={() =>
                  window.open(
                    "https://drive.google.com/file/d/1F5MBEqnZpdTtI5EF0QF6z2a_yjSKlFS1/view?usp=sharing",
                    "_blank"
                  )
                }
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <img
                  src={schedule}
                  alt="schedule"
                  style={{ width: "100%", padding: 0 }}
                />
              </button>
            </div>
          </div>
          {isModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  textAlign: "center",
                  width: "auto",
                  maxWidth: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    margin: "10px 0",
                    color: "#B57947",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  {location.isActive
                    ? "แจ้งเตือนกิจกรรม"
                    : "ท่านต้องการเดินทางไป"}
                </h3>

                {location.isActive &&
                getCurrentEvents(location.activities).length > 0 ? (
                  <div style={{ width: "100%", whiteSpace: "normal" }}>
                    <ul
                      style={{
                        textAlign: "left",
                        color: "#B57947",
                        fontSize: "16px",
                        paddingLeft: "30px",
                      }}
                    >
                      {getCurrentEvents(location.activities).map((item) => (
                        <li key={item.id}>{item.name}</li>
                      ))}
                    </ul>
                    <h5
                      style={{
                        textAlign: "center",
                        color: "#B57947",
                        fontSize: "18px",
                        margin: "5px",
                      }}
                    >
                      กำลังจะเริ่มในเวลา{" "}
                      {new Date(
                        getCurrentEvents(
                          location.activities
                        )[0].start_time.replace(" ", "T")
                      )
                        .toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                        .replace(":", ".")}{" "}
                      น.
                    </h5>
                  </div>
                ) : (
                  ""
                )}

                <h4
                  style={{
                    margin: "10px 0",
                    color: "#B57947",
                    fontSize: "17px",
                    whiteSpace: "normal",
                    fontWeight: "normal",
                  }}
                >
                  {location.isActive ? "บริเวณ " : ""} {location.name}
                </h4>

                <div
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <a
                    href={location.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      style={{
                        backgroundColor: "#B57947",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ fontSize: "24px" }}
                      />
                      <p style={{ margin: 0 }}>นำทางไปสถานที่จัดกิจกรรม</p>
                    </button>
                  </a>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={closeModal}
                    style={{
                      backgroundColor: "#B57947",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "15px",
                    }}
                  >
                    <p style={{ margin: 0 }}>ปิดหน้าต่าง</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
};
const styles = {
  container: {
    paddingTop: "90px",
  },
  Img: {
    width: "100%",
    height: "auto",
  },
  imgPlan: {
    width: "90%",
    height: "auto",
    cursor: "pointer",
  },
  btnLocation: {
    display: "flex",
    gap: "8px",
    padding: "12px",
    borderRadius: "8px",
    background: "#B57947",
    color: "white",
    border: "none",
    width: "100%",
    fontWeight: "bold",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    alignItems: "center",
  },
  textmap: {
    color: "#B57947",
    textDecoration: "none",
    wordWrap: "break-word",
  },
};

export default LandingPage;
