import React, { useEffect, useState } from "react";
// import Mapgps from "../Assets/MapPlan3.png";
import Mapgps from "../Assets/last_map.jpg";
// import PinImg from "../Assets/pin.png";
// import PinImg2 from "../Assets/pinTHdance.png";
// import PinImg3 from "../Assets/pinLive.png";
// import PinImg4 from "../Assets/pinFood.png";
// import testpin from "../Assets/testpin.png";
import blackpin from "../Assets/blackpin_small.png";
import livepin from "../Assets/livepin_small.png";
import dancepin from "../Assets/dancepin_small.png";
import foodpin from "../Assets/foodpin_small.png";
// import { logGPS } from "../Service/userApi.jsx";

// const SITE_LAT = 17.018507;
// const SITE_LON = 99.704719;

const Map = ({ locations, onMapClick }) => {
  const [userPosition, setUserPosition] = useState(null);
  const [nearbyPins, setNearbyPins] = useState([]);
  // const [currentDistance, setCurrentDistance] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  const [pins, setPins] = useState([]); // สเตทเก็บข้อมูลหมุด

  // const date = "2024-11-08"; // วันที่ที่ต้องการส่งไปยัง API

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const toRadians = (degree) => degree * (Math.PI / 180);
    const R = 6371; // ระยะรัศมีของโลกในกิโลเมตร
    const dLat = toRadians(lat2 - lat1);
    const dLng = toRadians(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000; // ผลลัพธ์จะเป็นระยะทางในเมตร
    return distance;
  };

  useEffect(() => {
    if (locations) {
      setPins(locations);
    }

    // ฟังก์ชันสำหรับเรียกใช้ setTimeout เพื่อรีเฟรช component ทุก 1 นาที
    const refreshTimeout = () => {
      setRefreshKey((prevKey) => (prevKey + 1) % Number.MAX_SAFE_INTEGER);
      setTimeout(refreshTimeout, 60000); // ตั้งเวลาสำหรับเรียกซ้ำทุก ๆ 1 นาที
    };
    // เรียก refreshTimeout ครั้งแรก
    refreshTimeout();

    //log lat lan
    // const intervalLatLan = setInterval(() => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         const latitude = position.coords.latitude;
    //         const longitude = position.coords.longitude;
    //         logGPS(latitude, longitude);
    //       },
    //       (error) => {
    //         console.error("Error getting GPS position:", error);
    //       }
    //     );
    //   } else {
    //     console.log("Geolocation is not supported by this browser.");
    //   }
    // }, 5 * 60 * 1000);

    let interval;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // ถ้าอยู่ใน background, หยุดการอัปเดตตำแหน่ง
        clearInterval(interval);
      } else {
        // ถ้าอยู่ใน foreground, เริ่มการอัปเดตตำแหน่ง
        updatePosition();
        interval = setInterval(updatePosition, 5000 * 60); // อัปเดตทุก 5 วินาที
      }
    };

    const updatePosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserPosition({ lat: latitude, lng: longitude });

            // คำนวณระยะห่างจากตำแหน่งผู้ใช้ไปยังหมุดทุกตัว
            const nearby = pins.filter((pin) => {
              const distance = calculateDistance(
                latitude,
                longitude,
                pin.lat,
                pin.lng
              );
              return distance < 1; // เปลี่ยนระยะห่างที่ต้องการ เช่น 1000 เมตร (1 กิโลเมตร)
            });

            setNearbyPins(nearby);

            // // notification position
            // const positionData = calculateDistance(
            //   latitude,
            //   longitude,
            //   SITE_LAT,
            //   SITE_LON
            // );
            // setCurrentDistance(positionData);

            // if (positionData > 1000) {
            //   window.PushAlertCo.unsubscribe();
            // }
          },
          (error) => {
            console.error("Geolocation error:", error);
            alert("ไม่สามารถเข้าถึงตำแหน่งได้"); // แจ้งผู้ใช้เมื่อไม่สามารถรับตำแหน่ง
          },
          { enableHighAccuracy: true }
        );
      }
    };

    // ตรวจสอบสถานะการซ่อน/แสดงหน้าเว็บ
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // เริ่มต้นการอัปเดตตำแหน่งหากอยู่ใน foreground
    if (!document.hidden) {
      updatePosition();
      interval = setInterval(updatePosition, 5000 * 60);
    }

    // เคลียร์ interval เมื่อ component ถูกทำลาย
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(interval);
    };
  }, []);

  // ฟังก์ชันสำหรับเช็คช่วงเวลา
  // const isInTimeRange = (startTime, endTime) => {
  //   const now = new Date();

  //   now.setMinutes(now.getMinutes() + 40);

  //   // แปลง startTime และ endTime เป็นเวลาไทย
  //   const start = new Date(
  //     new Date(startTime).toLocaleString("th-TH", { timeZone: "Asia/Bangkok" })
  //   );
  //   const end = new Date(
  //     new Date(endTime).toLocaleString("th-TH", { timeZone: "Asia/Bangkok" })
  //   );

  //   // ตรวจสอบว่าเวลาปัจจุบันอยู่ในช่วง start ถึง end หรือไม่
  //   return now >= start && now <= end ? "blue" : "red";
  // };

  const checkIsActive = (pin) => {
    let isActive = false;
    const arr = [];
    for (let i = 0; i < pin.activities?.length; i++) {
      const startTime = pin.activities[i].start_time;
      const endTime = pin.activities[i].end_time;

      const now = new Date();

      // แปลง startTime และ endTime เป็นเวลาไทย
      const start = new Date(startTime.replace(" ", "T"));
      start.setMinutes(start.getMinutes() - 15);
      const end = new Date(endTime.replace(" ", "T"));

      // ตรวจสอบว่าเวลาปัจจุบันอยู่ในช่วง start ถึง end หรือไม่
      isActive = now >= start && now <= end ? true : false;
      arr.push(isActive);
      //const color = isInTimeRange(startTime, endTime);
    }
    if (arr.some((value) => value === true)) {
      isActive = true;
    }

    return isActive;
  };

  const pinColor = (pin) => {
    //console.log('555555555555555555555555555555555555');

    for (let i = 0; i < pin.activities?.length; i++) {
      const startTime = pin.activities[i].start_time;
      const endTime = pin.activities[i].end_time;

      const now = new Date();

      //now.setMinutes(now.getMinutes() + 30);

      // แปลง startTime และ endTime เป็นเวลาไทย
      // const start = new Date(startTime.replace(" ", "T"));
      const start = new Date(startTime.replace(" ", "T"));
      start.setMinutes(start.getMinutes() - 15);
      const end = new Date(endTime.replace(" ", "T"));
      // end.setMinutes(end.getMinutes() + 300);

      // ตรวจสอบว่าเวลาปัจจุบันอยู่ในช่วง start ถึง end หรือไม่
      const color = now >= start && now <= end ? "red" : "white";
      //const color = isInTimeRange(startTime, endTime);
      if (color === "red") {
        return color;
      }
    }

    return "white";
    //return isInTimeRange(pin.start_time, pin.end_time);////////
  };

  return (
    <div key={refreshKey}>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img src={Mapgps} alt="Map" style={{ width: "100%" }} />
        {pins.map((pin) => {
          return (
            <a
              key={pin.id}
              href={pin.map_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                position: "absolute",
                top: pin.top,
                left: pin.left,
                width: "100%",
                maxWidth: "25px",
                height: "auto",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column", // จัดเรียงในแนวคอลัมน์
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                padding: "2px",
              }}
              onClick={(e) =>
                onMapClick(
                  e,
                  pin.name,
                  pin.map_url,
                  pin.activities,
                  checkIsActive(pin) ? true : false
                  // pinColor(pin) === "blue" ? true : false
                )
              }
            >
              {/* ตัวเลขวงกลม */}
              <div
                style={{
                  position: "absolute",
                  top: "-2.5vw",
                  left: "-2.5vw",
                  width: "5vw",
                  height: "5vw",
                  backgroundColor: pinColor(pin),
                  borderRadius: "50%",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  animation:
                    pinColor(pin) === "red" ? "blink 1s infinite" : "none",
                  zIndex: 1,
                }}
              >
                <span
                  style={{
                    color: checkIsActive(pin)
                      ? "white"
                      : [19, 18, 12].includes(pin.id)
                      ? "white"
                      : "black",
                    zIndex: 1,
                  }}
                >
                  {pin.id}
                </span>

                {/* รูปภาพ PinImg สำหรับ pin.id ที่ต้องการ */}
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 16, 20, 21, 22, 23, 24,
                  25, 26,
                ].includes(pin.id) && (
                  <img
                    key={pin.id}
                    src={checkIsActive(pin) ? livepin : blackpin} // ถ้า pin.active เป็น true ให้ใช้ PinImg3
                    alt="Pin"
                    style={{
                      position: "absolute",
                      top: "-0.3vw",
                      left: "-0.3vw",
                      // top: "-1px",
                      // left: "-2px",
                      // width: "25px",
                      width: "5.7vw",
                      // height: "12vw",
                      zIndex: 0,
                    }}
                  />
                )}

                {[17, 14, 15, 8].includes(pin.id) && (
                  <img
                    key={pin.name}
                    src={checkIsActive(pin) ? livepin : dancepin} // ใส่ path ของ PinImg2
                    alt="Pin"
                    style={{
                      position: "absolute",
                      // top: "-1px",
                      // left: "-2px",
                      // width: "25px",
                      top: "-0.3vw",
                      left: "-0.3vw",
                      width: "5.7vw",
                      // height: "12vw",
                      zIndex: 0,
                    }}
                  />
                )}

                {[19, 18, 12].includes(pin.id) && (
                  <img
                    key={pin.id}
                    src={checkIsActive(pin) ? livepin : foodpin} // ใส่ path ของ PinImg3 หรือ PinImg4
                    alt="Pin"
                    style={{
                      position: "absolute",
                      top: "-5vw",
                      left: "-1.1vw",
                      // top: pin.active ? "-3vw" : "-6.5vw", // ถ้า active ให้ขยับ top เป็น -3vw
                      // left: pin.active ? "-0.5vw" : "-0.6vw", // ถ้า active ให้ขยับ left เป็น -1vw
                      width: "7.5vw",
                      // height: "12vw",
                      zIndex: 0,
                    }}
                  />
                )}
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Map;
